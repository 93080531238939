import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/404', hidden: true },
  {
    path: '/404',
    name: '404',
    meta: { title: '404' },
    component: () => import(/* webpackChunkName: "404" */ '../views/errorPage/404.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: { title: '首页' },
    redirect: '/location',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
    children: [
      {
        path: '/location',
        name: 'location',
        meta: { title: '首页' },
        component: () => import(/* webpackChunkName: "location" */ '../views/location/index.vue')
      },
      {
        path: '/message',
        name: 'message',
        meta: { title: '消息' },
        component: () => import(/* webpackChunkName: "message" */ '../views/message/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: { title: '我的' },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: { title: '协议' },
    redirect: '/agreement/registerService',
    component: () => import(/* webpackChunkName: "agreement" */ '../views/agreement/index.vue'),
    children: [
      {
        path: 'registerService',
        name: 'registerService',
        meta: { title: '注册协议' },
        component: () => import(/* webpackChunkName: "registerService" */ '../views/agreement/registerService.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        meta: { title: '隐私政策' },
        component: () => import(/* webpackChunkName: "privacy" */ '../views/agreement/privacy.vue')
      },
      {
        path: '/complaintExplain',
        name: 'complaintExplain',
        meta: { title: '平安银行投诉处理流程说明' },
        component: () => import(/* webpackChunkName: "complaintExplain" */ '../views/agreement/complaintExplain.vue')
      },
      {
        path: '/bankCard',
        name: 'bankCard',
        meta: { title: '银行卡协议' },
        component: () => import(/* webpackChunkName: "bankCard" */ '../views/agreement/bankCard.vue')
      },
      {
        path: '/shareRule',
        name: 'shareRule',
        meta: { title: '活动规则' },
        component: () => import(/* webpackChunkName: "shareRule" */ '../views/agreement/shareRule.vue')
      }
    ]
  },
  {
    path: '/chatRoom/:your',
    name: 'chatRoom',
    meta: { title: '加载中' },
    component: () => import(/* webpackChunkName: "chatRoom" */ '../views/chatRoom/index.vue')
  },
  {
    path: '/chatServiceRoom/:your',
    name: 'chatServiceRoom',
    meta: { title: '跳转中' },
    component: () => import(/* webpackChunkName: "chatServiceRoom" */ '../views/chatServiceRoom/index.vue')
  },
  {
    path: '/redList',
    name: 'redList',
    meta: { title: '收到的新人礼包' },
    component: () => import(/* webpackChunkName: "redList" */ '../views/redList/index.vue')
  },
  {
    path: '/chatInterview',
    name: 'chatInterview',
    meta: { title: '约面谈' },
    component: () => import(/* webpackChunkName: "chatInterview" */ '../views/chatInterview/index.vue')
  },
  {
    path: '/chatInterviewInfo',
    name: 'chatInterviewInfo',
    meta: { title: '面谈详情' },
    component: () => import(/* webpackChunkName: "chatInterviewInfo" */ '../views/chatInterviewInfo/index.vue')
  },
  {
    path: '/managerCity',
    name: 'managerCity',
    meta: { title: '同城经理人' },
    component: () => import(/* webpackChunkName: "managerCity" */ '../views/managerCity/index.vue')
  },
  {
    path: '/aboutMdd',
    name: 'aboutMdd',
    meta: { title: '一分钟了解猫嘀嘀' },
    component: () => import(/* webpackChunkName: "aboutMdd" */ '../views/aboutMdd/index.vue')
  },
  {
    path: '/cityList',
    name: 'cityList',
    meta: { title: '选择城市' },
    component: () => import(/* webpackChunkName: "cityList" */ '../views/cityList/index.vue')
  },
  {
    path: '/loanInfo',
    name: 'loanInfo',
    meta: { title: '资料填写' },
    component: () => import(/* webpackChunkName: "loanInfo" */ '../views/loanInfo/index.vue')
  },
  {
    path: '/loanProgress/:orderId',
    name: 'loanProgress',
    meta: { title: '贷款进度' },
    component: () => import(/* webpackChunkName: "loanProgress" */ '../views/loanProgress/index.vue')
  },
  {
    path: '/loanEdit',
    name: 'loanEdit',
    meta: { title: '资料填写' },
    component: () => import(/* webpackChunkName: "loanEdit" */ '../views/loanEdit/index.vue')
  },
  {
    path: '/loanEndConfirm',
    name: 'loanEndConfirm',
    meta: { title: '结束贷款确认函' },
    component: () => import(/* webpackChunkName: "loanEndConfirm" */ '../views/loanEndConfirm/index.vue')
  },
  {
    path: '/loanScheme',
    name: 'loanScheme',
    meta: { title: '贷款方案' },
    component: () => import(/* webpackChunkName: "loanScheme" */ '../views/loanScheme/index.vue')
  },
  {
    path: '/chatLoan',
    name: 'chatLoan',
    meta: { title: '我的贷款' },
    component: () => import(/* webpackChunkName: "chatLoan" */ '../views/chatLoan/index.vue')
  },
  {
    path: '/chatFaceInfo',
    name: 'chatFaceInfo',
    meta: { title: '面签材料' },
    component: () => import(/* webpackChunkName: "chatFaceInfo" */ '../views/chatFaceInfo/index.vue')
  },
  {
    path: '/managerMatch/:orderId',
    name: 'managerMatch',
    meta: { title: '匹配结果' },
    component: () => import(/* webpackChunkName: "managerMatch" */ '../views/managerMatch/index.vue')
  },
  {
    path: '/managerDetail/:orderId/:userId/:your',
    name: 'managerDetail',
    meta: { title: '贷款详情' },
    component: () => import(/* webpackChunkName: "managerDetail" */ '../views/managerDetail/index.vue')
  },
  {
    path: '/managerPlan',
    name: 'managerPlan',
    meta: { title: '还款计划表' },
    component: () => import(/* webpackChunkName: "managerPlan" */ '../views/managerPlan/index.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    meta: { title: '设置' },
    component: () => import(/* webpackChunkName: "setting" */ '../views/setting/index.vue')
  },
  {
    path: '/settingAbout',
    name: 'settingAbout',
    meta: { title: '关于猫嘀嘀' },
    component: () => import(/* webpackChunkName: "settingAbout" */ '../views/settingAbout/index.vue')
  },
  {
    path: '/settingAccount',
    name: 'settingAccount',
    meta: { title: '帐号与安全' },
    component: () => import(/* webpackChunkName: "settingAccount" */ '../views/settingAccount/index.vue')
  },
  {
    path: '/settingFeedback',
    name: 'settingFeedback',
    meta: { title: '帮助与建议' },
    component: () => import(/* webpackChunkName: "settingFeedback" */ '../views/settingFeedback/index.vue')
  },
  {
    path: '/settingPassword',
    name: 'settingPassword',
    meta: { title: '设置密码' },
    component: () => import(/* webpackChunkName: "settingPassword" */ '../views/settingPassword/index.vue')
  },
  {
    path: '/userAuthentication',
    name: 'userAuthentication',
    meta: { title: '身份认证' },
    component: () => import(/* webpackChunkName: "userAuthentication" */ '../views/userAuthentication/index.vue')
  },
  {
    path: '/userManager',
    name: 'userManager',
    meta: { title: '我的经理人' },
    component: () => import(/* webpackChunkName: "userManager" */ '../views/userManager/index.vue')
  },
  {
    path: '/userIncome',
    name: 'userIncome',
    meta: { title: '数据中心' },
    component: () => import(/* webpackChunkName: "userIncome" */ '../views/userIncome/index.vue')
  },
  {
    path: '/userInviteDetail',
    name: 'userInviteDetail',
    meta: { title: '邀约详情' },
    component: () => import(/* webpackChunkName: "userInviteDetail" */ '../views/userInviteDetail/index.vue')
  },
  {
    path: '/userWithdrawal',
    name: 'userWithdrawal',
    meta: { title: '提现' },
    component: () => import(/* webpackChunkName: "userWithdrawal" */ '../views/userWithdrawal/index.vue')
  },
  {
    path: '/userWithdrawalDetail/:id',
    name: 'userWithdrawalDetail',
    meta: { title: '提现详情' },
    component: () => import(/* webpackChunkName: "userWithdrawalDetail" */ '../views/userWithdrawalDetail/index.vue')
  },
  {
    path: '/userWithdrawalList',
    name: 'userWithdrawalList',
    meta: { title: '提现记录' },
    component: () => import(/* webpackChunkName: "userWithdrawalList" */ '../views/userWithdrawalList/index.vue')
  },
  {
    path: '/userComplaint',
    name: 'userComplaint',
    meta: { title: '我的投诉' },
    component: () => import(/* webpackChunkName: "userComplaint" */ '../views/userComplaint/index.vue')
  },
  {
    path: '/userCoupon',
    name: 'userCoupon',
    meta: { title: '我的卡券' },
    component: () => import(/* webpackChunkName: "userCoupon" */ '../views/userCoupon/index.vue')
  },
  {
    path: '/userCouponDetail',
    name: 'userCouponDetail',
    meta: { title: '卡券详情' },
    component: () => import(/* webpackChunkName: "userCouponDetail" */ '../views/userCouponDetail/index.vue')
  },
  {
    path: '/userCouponRule',
    name: 'userCouponRule',
    meta: { title: '活动规则' },
    component: () => import(/* webpackChunkName: "userCouponRule" */ '../views/userCouponRule/index.vue')
  },
  {
    path: '/userComment',
    name: 'userComment',
    meta: { title: '评价' },
    component: () => import(/* webpackChunkName: "userComment" */ '../views/userComment/index.vue')
  },
  {
    path: '/userAuthenticationInfo',
    name: 'userAuthenticationInfo',
    meta: { title: '实名认证' },
    component: () => import(/* webpackChunkName: "userAuthenticationInfo" */ '../views/userAuthenticationInfo/index.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: { title: '个人信息' },
    component: () => import(/* webpackChunkName: "userInfo" */ '../views/userInfo/index.vue')
  },
  {
    path: '/userInfoEdit',
    name: 'userInfoEdit',
    meta: { title: '编辑资料' },
    component: () => import(/* webpackChunkName: "userInfoEdit" */ '../views/userInfoEdit/index.vue')
  },
  {
    path: '/userNicknameEdit',
    name: 'userNicknameEdit',
    meta: { title: '修改昵称' },
    component: () => import(/* webpackChunkName: "userNicknameEdit" */ '../views/userNicknameEdit/index.vue')
  },
  {
    path: '/userAvatarEdit',
    name: 'userAvatarEdit',
    meta: { title: '修改头像' },
    component: () => import(/* webpackChunkName: "userAvatarEdit" */ '../views/userAvatarEdit/index.vue')
  },
  {
    path: '/userShare',
    name: 'userShare',
    meta: { title: '邀好友赚现金' },
    component: () => import(/* webpackChunkName: "userShare" */ '../views/userShare/index.vue')
  },
  {
    path: '/userMaterial',
    name: 'userMaterial',
    meta: { title: '资质照片' },
    component: () => import(/* webpackChunkName: "userMaterial" */ '../views/userMaterial/index.vue')
  },
  {
    path: '/chatMaterial',
    name: 'chatMaterial',
    meta: { title: '初审材料' },
    component: () => import(/* webpackChunkName: "chatMaterial" */ '../views/chatMaterial/index.vue')
  },
  {
    path: '/chatLookMaterial',
    name: 'chatLookMaterial',
    meta: { title: '初审材料' },
    component: () => import(/* webpackChunkName: "chatLookMaterial" */ '../views/chatLookMaterial/index.vue')
  },
  {
    path: '/chatLoanPlan',
    name: 'chatLoanPlan',
    meta: { title: '贷款方案' },
    component: () => import(/* webpackChunkName: "chatLoanPlan" */ '../views/chatLoanPlan/index.vue')
  },
  {
    path: '/chatOrderProduct',
    name: 'chatOrderProduct',
    meta: { title: '我的产品' },
    component: () => import(/* webpackChunkName: "chatOrderProduct" */ '../views/chatOrderProduct/index.vue')
  },
  {
    path: '/chatLocation',
    name: 'chatLocation',
    meta: { title: '地理位置' },
    component: () => import(/* webpackChunkName: "chatLocation" */ '../views/chatLocation/index.vue')
  },
  {
    path: '/chatLookLocation',
    name: 'chatLookLocation',
    meta: { title: '位置' },
    component: () => import(/* webpackChunkName: "chatLookLocation" */ '../views/chatLookLocation/index.vue')
  },
  {
    path: '/bankList',
    name: 'bankList',
    meta: { title: '我的银行卡' },
    component: () => import(/* webpackChunkName: "bankList" */ '../views/bankList/index.vue')
  },
  {
    path: '/bankAdd',
    name: 'bankAdd',
    meta: { title: '添加银行卡' },
    component: () => import(/* webpackChunkName: "bankAdd" */ '../views/bankAdd/index.vue')
  },
  {
    path: '/salesmanInfo/:saleId',
    name: 'salesmanInfo',
    meta: { title: '个人详情' },
    component: () => import(/* webpackChunkName: "salesmanInfo" */ '../views/salesmanInfo/index.vue')
  },
  {
    path: '/lookPdf/:pdf',
    name: 'lookPdf',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "salesmanInfo" */ '../views/lookPdf/index.vue')
  }
]

const router = new VueRouter({
  routes,
  base: '/'
})

export default router
