import { getMyDetail, getShareUrls } from '@/api/user'
import { removeToken } from '@/utils/auth'
import WebIM from '@/utils/WebIM'
// import { wxUrl } from '@/utils/request'
import { Toast } from 'vant'
import store from '..'
const state = {
  userInfos: null,
  shareUrls: null,
  planList: null,
  sendSelectMsg: false,
  count: 0,
  faceInfo: { status: false }, // 面签资料暂存
  locationInfo: { status: false }, // 定位消息暂存
  selectLocation: null,
  planStatus: false,
  faceSignStatus: false,
  materialSendStatus: false, // 是否发送初审消息
  serviceSendStatus: false, // 是否发送服务协议消息
  city: {
    cityCode: '',
    adCode: '',
    latitude: '',
    longitude: '',
    areaName: ''
  },
  selectBank: null, // 选择的银行卡信息
  firstEnter: true,
  sendLoanStatus: false, // 是否发送贷款结束或者继续贷款信息
  allOrderStatus: {
    signStatus: 1,
    signStatus2: 1,
    agreementType: 0,
    userUploadOrView: '',
    reFinancing: 1,
    hasConfirmPlan: 1,
    faceMaterials: null,
    saleSendFaceMaterials: null,
    isDone: 1,
    isOrder: 2
  }
}

const mutations = {
  SET_USERINFO: (state, userInfos) => {
    state.userInfos = userInfos
  },
  SET_USERINFO_ATTR: (state, userInfos) => {
    for (const key in userInfos) {
      state.userInfos[key] = userInfos[key]
    }
  },
  SET_CITY_ATTR: (state, city) => {
    for (const key in city) {
      state.city[key] = city[key]
    }
  },
  SET_SHARE_URL: (state, url) => {
    state.shareUrls = url
  },
  SET_PLAN_LIST: (state, list) => {
    state.planList = list
  },
  SET_SEND_SELECT_MSG: (state, flag) => {
    state.sendSelectMsg = flag
  },
  SET_COUNT: (state, count) => {
    state.count = count
  },
  SET_FIRST_ENTER: (state, flag) => {
    state.firstEnter = flag
  },
  SET_FACE_INFO: (state, faceInfo) => {
    state.faceInfo = faceInfo
  },
  SET_LOCATION_INFO: (state, locationInfo) => {
    state.locationInfo = locationInfo
  },
  SET_SELECT_LOCATION: (state, locationInfo) => {
    state.selectLocation = locationInfo
  },
  SET_PLAN_STATUS: (state, flag) => {
    state.planStatus = flag
  },
  SET_FACE_SIGN_STATUS: (state, flag) => {
    state.faceSignStatus = flag
  },
  SET_SELECT_BANK: (state, bank) => {
    state.selectBank = bank
  },
  SET_ALL_ORDER_STATUS: (state, datas) => {
    state.allOrderStatus = datas
  },
  SET_MATERIAL_SEND_STATUS: (state, datas) => {
    state.materialSendStatus = datas
  },
  SET_SERVICE_SEND_STATUS: (state, datas) => {
    state.serviceSendStatus = datas
  },
  SET_SEND_LOAN_STATUS: (state, flag) => {
    state.sendLoanStatus = flag
  }
}

const getters = {
  userInfos: async state => state.userInfos
}

const actions = {
  // 获取用户个人信息
  async getMyDetail({ commit }) {
    try {
      const loading = Toast.loading()
      const res = await getMyDetail()
      localStorage.setItem('myUsername', res.datas.eaUserName)
      WebIM.conn.open({
        apiUrl: WebIM.config.apiURL,
        user: res.datas.eaUserName,
        pwd: res.datas.eaPwd,
        grant_type: 'password',
        appKey: WebIM.config.appkey
      })
      loading.clear()
      commit('SET_USERINFO', res.datas || {})
    } catch (error) {
      commit('SET_USERINFO', null)
      removeToken()
    }
  },
  async setUserInfos({ commit }, infos) {
    commit('SET_USERINFO_ATTR', infos)
  },
  async setPlanList({ commit }, list) {
    commit('SET_PLAN_LIST', list)
  },
  async getShareUrls({ commit }, url) {
    const loading = Toast.loading({ forbidClick: true, duration: 0 })
    const res = await getShareUrls(url)
    commit('SET_SHARE_URL', res.datas)
    loading.clear()
  },
  async loginOut({ commit }) {
    store.dispatch('global/setExpiration', 0)
    commit('SET_USERINFO', null)
    commit('SET_FIRST_ENTER', true)
    removeToken()
  },
  async setLocation({ commit }, city) {
    commit('SET_CITY_ATTR', city)
  },
  async setSelectMsgStatus({ commit }, flag) {
    commit('SET_SEND_SELECT_MSG', flag)
  },
  async setCount({ commit }, count) {
    commit('SET_COUNT', count)
  },
  async setFirstEnter({ commit }, flag) {
    commit('SET_FIRST_ENTER', flag)
  },
  async setFaceInfo({ commit }, faceInfo) {
    commit('SET_FACE_INFO', faceInfo)
  },
  async setLocationInfo({ commit }, locationInfo) {
    commit('SET_LOCATION_INFO', locationInfo)
  },
  async setSelectLocation({ commit }, locationInfo) {
    commit('SET_SELECT_LOCATION', locationInfo)
  },
  async setPlanStatus({ commit }, flag) {
    commit('SET_PLAN_STATUS', flag)
  },
  async setMaterialSendStatus({ commit }, flag) {
    commit('SET_MATERIAL_SEND_STATUS', flag)
  },
  async setServiceSendStatus({ commit }, flag) {
    commit('SET_SERVICE_SEND_STATUS', flag)
  },
  async setFaceSignStatus({ commit }, flag) {
    commit('SET_FACE_SIGN_STATUS', flag)
  },
  async selectBank({ commit }, flag) {
    console.log('---------------===========')
    commit('SET_SELECT_BANK', flag)
  },
  async getAllOrderStatus({ commit }, datas) {
    commit('SET_ALL_ORDER_STATUS', datas)
  },
  async setSendLoanStatus({ commit }, flag) {
    commit('SET_SEND_LOAN_STATUS', flag)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
