/* eslint-disable new-cap */
/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
import websdk from 'easemob-websdk'
import config from './WebIMConfig'
import disp from '@/utils/broadcast'
import msgStorage from '@/components/chat/msgstorage'
import msgType from '@/components/chat/msgtype'
import store from '@/store'
import { Toast } from 'vant'
var window = {}
const WebIM = window.WebIM = websdk
window.WebIM.config = config

WebIM.isDebug = function(option) {
  if (option) {
    WebIM.config.isDebug = option.isDebug
    openDebug(WebIM.config.isDebug)
  }

  function openDebug(value) {
    // function ts() {
    //   var d = new Date()
    //   var Hours = d.getHours() // 获取当前小时数(0-23)

    //   var Minutes = d.getMinutes() // 获取当前分钟数(0-59)

    //   var Seconds = d.getSeconds() // 获取当前秒数(0-59)

    //   return (Hours < 10 ? '0' + Hours : Hours) + ':' + (Minutes < 10 ? '0' + Minutes : Minutes) + ':' + (Seconds < 10 ? '0' + Seconds : Seconds) + ' '
    // }
  }
}
/**
 * Set autoSignIn as true (autoSignInName and autoSignInPwd are configured below),
 * You can auto signed in each time when you refresh the page in dev model.
 */

WebIM.config.autoSignIn = false

if (WebIM.config.autoSignIn) {
  WebIM.config.autoSignInName = 'lwz2'
  WebIM.config.autoSignInPwd = '1'
}

WebIM.parseEmoji = function(msg) {
  if (typeof WebIM.Emoji === 'undefined' || typeof WebIM.Emoji.map === 'undefined') {
    return msg
  }

  var emoji = WebIM.Emoji
  var reg = null
  var msgList = []
  var objList = []

  for (var face in emoji.map) {
    if (emoji.map.hasOwnProperty(face)) {
      while (msg.indexOf(face) > -1) {
        msg = msg.replace(face, '^' + emoji.map[face] + '^')
      }
    }
  }

  var ary = msg.split('^')
  reg = /^e.*g$/

  for (let i = 0; i < ary.length; i++) {
    if (ary[i] != '') {
      msgList.push(ary[i])
    }
  }

  for (let i = 0; i < msgList.length; i++) {
    if (reg.test(msgList[i])) {
      const obj = {}
      obj.data = msgList[i]
      obj.type = 'emoji'
      objList.push(obj)
    } else {
      const obj = {}
      obj.data = msgList[i]
      obj.type = 'txt'
      objList.push(obj)
    }
  }

  return objList
}

WebIM.time = function() {
  var date = new Date()
  var Hours = date.getHours()
  var Minutes = date.getMinutes()
  var Seconds = date.getSeconds()
  var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + (Hours < 10 ? '0' + Hours : Hours) + ':' + (Minutes < 10 ? '0' + Minutes : Minutes) + ':' + (Seconds < 10 ? '0' + Seconds : Seconds)
  return time
}

WebIM.Emoji = {
  path: '../static/images/faces/',
  map: {
    '[):]': 'ee_1.png',
    '[:D]': 'ee_2.png',
    '[;)]': 'ee_3.png',
    '[:-o]': 'ee_4.png',
    '[:p]': 'ee_5.png',
    '[(H)]': 'ee_6.png',
    '[:@]': 'ee_7.png',
    '[:s]': 'ee_8.png',
    '[:$]': 'ee_9.png',
    '[:(]': 'ee_10.png',
    "[:'(]": 'ee_11.png',
    '[<o)]': 'ee_12.png',
    '[(a)]': 'ee_13.png',
    '[8o|]': 'ee_14.png',
    '[8-|]': 'ee_15.png',
    '[+o(]': 'ee_16.png',
    '[|-)]': 'ee_17.png',
    '[:|]': 'ee_18.png',
    '[*-)]': 'ee_19.png',
    '[:-#]': 'ee_20.png',
    '[^o)]': 'ee_21.png',
    '[:-*]': 'ee_22.png',
    '[8-)]': 'ee_23.png',
    '[del]': 'btn_del.png',
    '[(|)]': 'ee_24.png',
    '[(u)]': 'ee_25.png',
    '[(S)]': 'ee_26.png',
    '[(*)]': 'ee_27.png',
    '[(#)]': 'ee_28.png',
    '[(R)]': 'ee_29.png',
    '[({)]': 'ee_30.png',
    '[(})]': 'ee_31.png',
    '[(k)]': 'ee_32.png',
    '[(F)]': 'ee_33.png',
    '[(W)]': 'ee_34.png',
    '[(D)]': 'ee_35.png'
  }
}
WebIM.EmojiObj = {
  // 相对 emoji.js 路径
  path: './assets/faces',
  map1: {
    '[):]': 'ee_1.png',
    '[:D]': 'ee_2.png',
    '[;)]': 'ee_3.png',
    '[:-o]': 'ee_4.png',
    '[:p]': 'ee_5.png',
    '[(H)]': 'ee_6.png',
    '[:@]': 'ee_7.png'
  },
  map2: {
    '[:s]': 'ee_8.png',
    '[:$]': 'ee_9.png',
    '[:(]': 'ee_10.png',
    "[:'(]": 'ee_11.png',
    '[<o)]': 'ee_12.png',
    '[(a)]': 'ee_13.png',
    '[8o|]': 'ee_14.png'
  },
  map3: {
    '[8-|]': 'ee_15.png',
    '[+o(]': 'ee_16.png',
    '[|-)]': 'ee_17.png',
    '[:|]': 'ee_18.png',
    '[*-)]': 'ee_19.png',
    '[:-#]': 'ee_20.png',
    '[del]': 'del.png'
  },
  map4: {
    '[^o)]': 'ee_21.png',
    '[:-*]': 'ee_22.png',
    '[8-)]': 'ee_23.png',
    '[(|)]': 'ee_24.png',
    '[(u)]': 'ee_25.png',
    '[(S)]': 'ee_26.png',
    '[(*)]': 'ee_27.png',
    '[(D)]': 'ee_35.png'
  },
  map5: {
    '[(#)]': 'ee_28.png',
    '[(R)]': 'ee_29.png',
    '[({)]': 'ee_30.png',
    '[(})]': 'ee_31.png',
    '[(k)]': 'ee_32.png',
    '[(F)]': 'ee_33.png',
    '[(W)]': 'ee_34.png',
    '[del]': 'del.png'
  },
  map6: {
  }
}

WebIM.conn = new WebIM.connection({
  appKey: WebIM.config.appkey,
  isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
  https: typeof WebIM.config.https === 'boolean' ? WebIM.config.https : location.protocol === 'https:',
  isAutoLogin: false,
  heartBeatWait: WebIM.config.heartBeatWait,
  autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
  autoReconnectInterval: WebIM.config.autoReconnectInterval,
  isDebug: false,
  isHttpDNS: true
})

function ack(receiveMsg) {
  // 处理未读消息回执
  var bodyId = receiveMsg.id // 需要发送已读回执的消息id

  var ackMsg = new WebIM.message('read', WebIM.conn.getUniqueId())
  ackMsg.set({
    id: bodyId,
    to: receiveMsg.from
  })
  WebIM.conn.send(ackMsg.body)
}
function onMessageError(err) {
  if (err.type === 'error') {
    Toast.fail(err.errorText)
    return false
  }

  return true
}

// 包含陌生人版本
function calcUnReadSpot(message) {
  const myName = localStorage.getItem('myUsername')
  const storageKeys = []
  for (let i = 0; i < localStorage.length; i++) {
    storageKeys.push(localStorage.key(i))
  }
  const newChatMsgKeys = []
  storageKeys.forEach((item) => {
    if (item.indexOf(myName) > -1 && item.indexOf('rendered_') == -1) {
      newChatMsgKeys.push(item)
    }
  })
  const count = newChatMsgKeys.reduce(function(result, curMember, idx) {
    const chatMsgs = JSON.parse(localStorage.getItem(curMember)) || []
    console.log(result, chatMsgs.length, '000')
    return result + chatMsgs.length
  }, 0)
  console.log(count, 'calcUnReadSpot')
  store.dispatch('user/setCount', count)
  disp.fire('em.unreadspot', message)
}

disp.on('em.main.ready', function() {
  calcUnReadSpot()
})
disp.on('em.chatroom.leave', function() {
  calcUnReadSpot()
})
disp.on('em.chat.session.remove', function() {
  calcUnReadSpot()
})

WebIM.conn.listen({
  onOpened(message) {
    console.log('onOpened', message)
  },

  onReconnect() {
    Toast.loading({
      message: '重连中...',
      forbidClick: true
    })
  },

  onSocketConnected() {
    Toast.success('连接成功')
  },

  onClosed() {
    console.log('退出环信')
    WebIM.conn.close()
  },

  onReadMessage(message) {
    console.log('已读', message)
  },
  onLocationMessage(message) {
    if (message) {
      message.ext.h5Address = message.addr
      message.ext.h5Lat = message.lat
      message.ext.h5Lng = message.lng
      console.log(message, 'messagemessagemessagemessagemessage')
      msgStorage.saveReceiveMsg(message, msgType.LOCATION)
    }
    calcUnReadSpot(message)
    ack(message)
  },
  onTextMessage(message) {
    console.log('onTextMessage', message)
    if (message) {
      if (onMessageError(message)) {
        msgStorage.saveReceiveMsg(message, msgType.TEXT)
      }
      calcUnReadSpot(message)
      ack(message)
    }
  },

  onEmojiMessage(message) {
    console.log('onEmojiMessage', message)
    if (message) {
      if (onMessageError(message)) {
        msgStorage.saveReceiveMsg(message, msgType.EMOJI)
      }
      calcUnReadSpot(message)
      ack(message)
    }
  },

  // 各种异常
  onError(error) {
    console.log(error, 'error')

    if (error.type == WebIM.statusCode.WEBIM_CONNCTION_OPEN_ERROR) {
      Toast.clear()
      disp.fire('em.error.passwordErr')
    }

    if (error.type == WebIM.statusCode.WEBIM_CONNCTION_AUTH_ERROR) {
      Toast.clear()
      disp.fire('em.error.tokenErr')
    }

    if (error.type == 'socket_error') {
      // /sendMsgError
      console.log('socket_errorsocket_error', error)
      Toast.fail('网络已断开')
      disp.fire('em.error.sendMsgErr', error)
    }
  }
})

export default WebIM

