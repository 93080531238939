<template>
  <div id="app">
    <keep-alive :include="keepList">
      <router-view :key="$route.fullPath" />
    </keep-alive>
    <div id="red-popup" />
    <div id="salesman-popup" />
    <div id="loan-success-popup" />
    <div id="toast" />
  </div>
</template>
<script>
import disp from '@/utils/broadcast'
export default {
  data() {
    return {
      keepList: ['Login', 'ManagerMatch', 'ManagerDetail', 'BankAdd', 'ChatFaceInfo', 'ChatInterview']
    }
  },
  mounted() {
    disp.fire('em.main.ready')
  }
}
</script>
<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
}
#app {
  font-size: 14px;
  background: #fdfdfd;
}
p {
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
}
/*协议内容*/
.pact-box{
  height: 100%;
  padding: .4rem;
  line-height: .8rem;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  overflow-y: auto;
}
.pact-box h1{
    font-size: .6rem;
    text-align: center;
}
.pact-box h2{
    text-align: center;
    margin:.3rem 0;
    font-size: .5rem;
}
.pact-box h3{
    font-size: .45rem;
}
.pact-box p{
    font-size: .4rem;
    margin:.4rem 0;
}
.pact-box .btn{
    padding:.5rem 0;
    text-align: center;
}
.pact-box .btn .link{
    display: inline-block;
    padding: .2rem 2rem;
    border:0;
    color:#333332;
    font-size: .4rem;
    background:linear-gradient(90deg,rgba(237,221,148,1) 0%,rgba(177,150,85,1) 99%);
    box-shadow:0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: .5rem;
}
.no-data {
  text-align: center;
  color: #ccc;
}
// 自定义dialog弹框样式
.my-dialog {
  .van-dialog__footer {
    .van-dialog__cancel, .van-dialog__confirm {
      height: 36px;
      margin: 0 10px;
      border-radius: 100px;
    }
    .van-dialog__cancel {
      color: #666666;
      background: #e0e0e0;
    }
    .van-dialog__confirm {
      background: linear-gradient(90deg, #FE9D02 0%, #F78017 100%);
    }
  }
}
// 自定义上拉加载样式
.__vuescroll .__refresh svg.start .active-path, .__vuescroll .__load svg.start .active-path {
  stroke: #c8c9cc !important;
}
.__vuescroll .__refresh svg path, .__vuescroll .__refresh svg rect, .__vuescroll .__load svg path, .__vuescroll .__load svg rect {
  fill:  #c8c9cc !important;
}
.__vuescroll .__refresh svg, .__vuescroll .__load svg {
  width: 15px !important;
  height: 15px !important;
}
.__vuescroll .__refresh, .__vuescroll .__load {
  color: #969799 !important;
}

// 自定义按钮样式
.van-button--info {
  border-color: #5170C3;
  background: #5170C3;
}

.box {
  padding: 0 12px;
  background: #FFFFFF;
  border-radius: 8px;
}
// 去除input默认小眼睛
input[type="password"]::-ms-reveal{
 display:none
}

.dialog-box {
  color: #000;
  .van-dialog__footer {
    padding: 20px 60px;
  }
  .van-dialog__confirm {
    height: 45px;
  }
}
</style>
