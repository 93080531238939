import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Tab, Tabs, Icon, Popover, Button, Field, Form, Popup, Toast, Checkbox, List, Step, Steps, Dialog, Area, Image, SwipeCell, Lazyload, Progress, TreeSelect, Loading, PullRefresh, Switch, DatetimePicker, Picker, Rate, Uploader, Radio, RadioGroup, CellGroup, Cell, Empty, Swipe, SwipeItem, IndexBar, IndexAnchor, NoticeBar } from 'vant'
import 'amfe-flexible'
import '@/permission'
import vuescroll from 'vuescroll/dist/vuescroll-slide'

// 强制https访问
// if (location.protocol === 'http:') {
//   location.href = 'https:' + location.host + location.pathname
// }

// 引入上拉加载组件
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'slide',
      pushLoad: {
        enable: true,
        autoLoadDistance: 10,
        tips: {
          deactive: '上拉加载',
          active: '释放加载',
          start: '加载中...',
          beforeDeactive: '加载成功!'
        }
      }
    },
    rail: {
      size: '0'
    },
    bar: {
      size: '2px',
      background: '#c1c1c1'
    }
  }, // 在这里设置全局默认配置
  name: 'VueScroll' // 在这里自定义组件名字，默认是vueScroll
})

// 按需导入vant组件
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Popover)
Vue.use(Button)
Vue.use(Field)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(List)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(Area)
Vue.use(Lazyload, {
  listenEvents: ['transitionend']
})
Vue.use(Loading)
Vue.use(PullRefresh)
Vue.use(Switch)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Rate)
Vue.use(Empty)
Vue.use(SwipeCell)
Vue.use(Swipe)
Vue.use(SwipeItem).use(IndexBar).use(IndexAnchor).use(Progress).use(TreeSelect).use(Checkbox).use(NoticeBar)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
