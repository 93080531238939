import { getOssInfo } from '@/api/base'
const OSS = require('ali-oss')
const state = {
  ossStore: null, // oss实例对象
  OssExpiration: 0 // oss过期时间
}

const mutations = {
  SET_OSSSTORE: (state, { credentials }) => {
    state.ossStore = new OSS({
      bucket: 'yumaokeji',
      region: 'oss-cn-shenzhen',
      accessKeyId: credentials.accessKeyId,
      stsToken: credentials.securityToken,
      accessKeySecret: credentials.accessKeySecret
    })
    state.OssExpiration = new Date(credentials.expiration).getTime()
  },
  SET_EXPIRATION: (state, time) => {
    state.OssExpiration = time
  }
}

const getters = {
  ossStore: async state => state.ossStore,
  isShowLoading: state => state.isShowLoading
}

const actions = {
  // oos
  async getOssStore({ commit }) {
    try {
      const res = await getOssInfo()
      if (res.resp_code === 0) {
        commit('SET_OSSSTORE', res.datas)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async setExpiration({ commit }, time) {
    commit('SET_EXPIRATION', time)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
