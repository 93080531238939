import store from '@/store'
import WebIM from '@/utils/WebIM'
const TokenKey = 'Authorization'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  try {
    WebIM.conn.close()
  } catch {
    //
  }
  return localStorage.removeItem(TokenKey)
}

export function setLocalUserInfo(data) {
  const key = store.getters.userInfos.userId
  const datas = JSON.parse(localStorage.getItem(key))
  localStorage.setItem(key, JSON.stringify({ ...datas, ...data }))
}
export function getLocalUserInfo() {
  const key = store.getters.userInfos.userId
  return JSON.parse(localStorage.getItem(key)) || {}
}
