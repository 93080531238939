/* eslint-disable eqeqeq */
import Disp from '@/utils/Dispatcher'
import msgPackager from './msgpackager'
import msgType from './msgtype.js'

const msgStorage = new Disp()

msgStorage.saveReceiveMsg = function(receiveMsg, type) {
  let sendableMsg

  if (type == msgType.TEXT || type == msgType.EMOJI) {
    sendableMsg = {
      id: receiveMsg.id,
      type: type,
      body: {
        id: receiveMsg.id,
        from: receiveMsg.from,
        to: receiveMsg.to,
        type: receiveMsg.type,
        ext: receiveMsg.ext,
        chatType: receiveMsg.type,
        toJid: '',
        body: {
          type: type,
          msg: receiveMsg.data
        }
      },
      value: receiveMsg.data
    }
  } else if (type == msgType.LOCATION) {
    sendableMsg = {
      id: receiveMsg.id,
      type: type,
      body: {
        id: receiveMsg.id,
        from: receiveMsg.from,
        to: receiveMsg.to,
        type: receiveMsg.type,
        ext: receiveMsg.ext,
        chatType: receiveMsg.type,
        toJid: '',
        body: {
          type: type,
          msg: receiveMsg.data
        }
      },
      value: receiveMsg.data
    }
  } else {
    return
  }

  this.saveMsg(sendableMsg, type, receiveMsg)
}

msgStorage.saveMsg = function(sendableMsg, type, receiveMsg) {
  console.log(sendableMsg, 'pppppppppppp')
  const me = this
  const myName = localStorage.getItem('myUsername')
  let sessionKey // 仅用作群聊收消息，发消息没有 receiveMsg

  if (receiveMsg && receiveMsg.type == 'groupchat') {
    sessionKey = receiveMsg.to + myName
  } else if (sendableMsg.body.chatType === 'INFORM') {
    sessionKey = 'INFORM'
  } else {
    sessionKey = sendableMsg.body.from == myName ? sendableMsg.body.to + myName : sendableMsg.body.from + myName
  }

  const curChatMsg = JSON.parse(localStorage.getItem(sessionKey)) || []
  const renderableMsg = msgPackager(sendableMsg, type, myName)
  curChatMsg.push(renderableMsg) // console.log('renderableMsgrenderableMsg', renderableMsg)

  save()

  function save() {
    console.log(curChatMsg, '111')
    localStorage.setItem(sessionKey, JSON.stringify(curChatMsg))
    me.fire('newChatMsg', renderableMsg, type, curChatMsg, sessionKey)
  }
}

export default msgStorage
