import { getJsapiTicket } from '@/api/bank'
import { Toast } from 'vant'
import { initWxConfig } from '@/utils/wx.config'
const state = {
  jsapiTicket: null
}

const mutations = {
  SET_JSAPI_TICKET: (state, jsapiTicket) => {
    state.jsapiTicket = jsapiTicket
  }
}

const getters = {
  jsapiTicket: async state => state.jsapiTicket
}

const actions = {
  // 加载jssdk
  async initWxConfig({ commit, state }, url) {
    if (state.jsapi_ticket) {
      initWxConfig(state.jsapi_ticket, url)
    } else {
      const loading = Toast.loading({ forbidClick: true, duration: 0 })
      const res = await getJsapiTicket()
      loading.clear()
      commit('SET_JSAPI_TICKET', res.datas)
      initWxConfig(res.datas, url)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
