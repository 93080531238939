import router from './router'
import { getToken } from '@/utils/auth' // get token from cookie
import store from './store'
console.log(this, '======')

const whiteList = ['login', 'registerService', 'privacy', '404', 'productService'] // 不需要登录就能访问的页面

router.beforeEach(async(to, from, next) => {
  console.log(to, from, '进入路由守卫')
  // set page title
  document.title = to.meta.title

  // determine whether the user has logged in
  const hasToken = getToken()
  console.log(from, 'from')
  console.log(to, 'to')
  if (hasToken) {
    if (to.name === 'login') {
      next({ path: '/' })
    } else if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      await store.dispatch('user/getMyDetail')
      const shareUrls = store.getters.shareUrls
      if (shareUrls) {
        next()
      } else {
        await store.dispatch('user/getShareUrls', {
          keys: [
            'about_us',
            'invite_index',
            'invite_data',
            'invite_list',
            'withdrawal_list',
            'withdrawal_detail',
            'invite_share_rule'
          ]
        })
        next({ ...to, replace: true })
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {

})

router.onError((err) => {
  console.log(err, '路由报错')
})
