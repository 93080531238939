const getters = {
  //   token: state => state.auth.token
  ossStore: state => state.global.ossStore,
  userInfos: state => state.user.userInfos,
  faceInfo: state => state.user.faceInfo,
  locationInfo: state => state.user.locationInfo,
  selectLocation: state => state.user.selectLocation,
  planStatus: state => state.user.planStatus,
  faceSignStatus: state => state.user.faceSignStatus,
  serviceSendStatus: state => state.user.serviceSendStatus,
  materialSendStatus: state => state.user.materialSendStatus,
  sendLoanStatus: state => state.user.sendLoanStatus,
  selectBank: state => state.user.selectBank,
  shareUrls: state => state.user.shareUrls,
  city: state => state.user.city,
  planList: state => state.user.planList,
  sendSelectMsg: state => state.user.sendSelectMsg,
  count: state => state.user.count,
  firstEnter: state => state.user.firstEnter,
  allOrderStatus: state => state.user.allOrderStatus
}
export default getters
