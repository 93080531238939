
import store from '@/store'
const wx = require('weixin-js-sdk')
function randomString(len) {
  len = len || 32
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
function encodeUTF8(s) {
  var i; var r = []; var c; var x
  for (i = 0; i < s.length; i++) {
    if ((c = s.charCodeAt(i)) < 0x80) r.push(c)
    else if (c < 0x800) r.push(0xC0 + (c >> 6 & 0x1F), 0x80 + (c & 0x3F))
    else {
      // eslint-disable-next-line eqeqeq
      if ((x = c ^ 0xD800) >> 10 == 0) // 对四字节UTF-16转换为Unicode
      // eslint-disable-next-line brace-style
      {
        // eslint-disable-next-line no-sequences
        c = (x << 10) + (s.charCodeAt(++i) ^ 0xDC00) + 0x10000,
        r.push(0xF0 + (c >> 18 & 0x7), 0x80 + (c >> 12 & 0x3F))
      } else r.push(0xE0 + (c >> 12 & 0xF))
      r.push(0x80 + (c >> 6 & 0x3F), 0x80 + (c & 0x3F))
    }
  }
  return r
}
function sha1(s) {
  var data = new Uint8Array(encodeUTF8(s))
  var i, j, t
  // eslint-disable-next-line no-redeclare
  var l = ((data.length + 8) >>> 6 << 4) + 16; var s = new Uint8Array(l << 2)
  // eslint-disable-next-line no-sequences
  s.set(new Uint8Array(data.buffer)), s = new Uint32Array(s.buffer)
  for (t = new DataView(s.buffer), i = 0; i < l; i++)s[i] = t.getUint32(i << 2)
  s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8)
  s[l - 1] = data.length << 3
  var w = []; var f = [
    function() { return m[1] & m[2] | ~m[1] & m[3] },
    function() { return m[1] ^ m[2] ^ m[3] },
    function() { return m[1] & m[2] | m[1] & m[3] | m[2] & m[3] },
    function() { return m[1] ^ m[2] ^ m[3] }
  ]; var rol = function(n, c) { return n << c | n >>> (32 - c) }
  var k = [1518500249, 1859775393, -1894007588, -899497514]
  var m = [1732584193, -271733879, null, null, -1009589776]
  // eslint-disable-next-line no-sequences
  m[2] = ~m[0], m[3] = ~m[1]
  for (i = 0; i < s.length; i += 16) {
    var o = m.slice(0)
    for (j = 0; j < 80; j++) {
      // eslint-disable-next-line no-sequences
      w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1),
      t = rol(m[0], 5) + f[j / 20 | 0]() + m[4] + w[j] + k[j / 20 | 0] | 0,
      m[1] = rol(m[1], 30), m.pop(), m.unshift(t)
    }
    for (j = 0; j < 5; j++)m[j] = m[j] + o[j] | 0
  }
  t = new DataView(new Uint32Array(m).buffer)
  for (let i = 0; i < 5; i++)m[i] = t.getUint32(i << 2)

  var hex = Array.prototype.map.call(new Uint8Array(new Uint32Array(m).buffer), function(e) {
    return (e < 16 ? '0' : '') + e.toString(16)
  }).join('')
  return hex
}
export function initWxConfig(jsapi_ticket, url) {
  console.log(store.getters.shareUrl, '11111')
  const nonceStr = randomString(16)
  const timestamp = (Date.now() / 1000).toFixed(0)
  const str = `jsapi_ticket=${jsapi_ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${url}`
  console.log(str, '2222')
  const signature = sha1(str)
  console.log(signature, 'signature')
  const wxConfig = {
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: 'wx3a645fa703ae7a8d', // 必填，公众号的唯一标识
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'openLocation'] // 必填，需要使用的JS接口列表
  }
  wx.config(wxConfig)
  wx.error(error => {
    console.log(error, 'error')
  })
}
export function setShare({ title, desc, link, imgUrl }) {
  wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
    console.log(title, desc, link, imgUrl, '成功了')
    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
    wx.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
      success: function() {
        console.log('分享设置成功！')
        // 设置成功
      }
    })

    wx.updateTimelineShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      success: function() {
        // 设置成功
      }
    })
  })
}
export function openLocation({ latitude, longitude, address }) {
  wx.ready(function() {
    wx.openLocation({
      type: 'gcj02',
      latitude,
      longitude,
      name: '',
      scale: 18,
      address
    })
  })
}
