import request from '@/utils/request'
import qs from 'qs'
const baseApi = '/api-bank'
// 帐号密码登录
export function login(data) {
  return request({
    url: baseApi + '/bank/user/login',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 短信验证码登录(经纪人)
export function smsLogin(data) {
  return request({
    url: baseApi + '/bank/user/smsLogin',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 短信验证码登录(用户)
export function userSmsLogin(data) {
  return request({
    url: baseApi + '/bank/applyUser/smsLogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 校验短信 type 1 返回加密手机串
export function checkSms(params) {
  return request({
    url: baseApi + '/bank/user/checkSms',
    method: 'post',
    params
  })
}

// 重置密码
export function restPassword(params) {
  return request({
    url: baseApi + '/bank/user/restPassword',
    method: 'post',
    params
  })
}

// 申请贷款
export function applyLoan(data) {
  return request({
    url: baseApi + '/bank/order/applyLoan',
    method: 'post',
    data
  })
}
// 根据微信授权信息,更新用户信息
export function updateUserByWX(params) {
  return request({
    url: baseApi + '/bank/user/updateUserByWX',
    method: 'get',
    params
  })
}
// 保存名片设置
export function saveCardSetting(data) {
  return request({
    url: baseApi + '/bank/user/saveCardSetting',
    method: 'post',
    data
  })
}
// 获取名片设置
export function getCardSetting(params) {
  return request({
    url: baseApi + '/bank/user/getCardSetting',
    method: 'get',
    params
  })
}
// 获取名片信息，不需要token，code为id加密
export function getCardInfo(params) {
  return request({
    url: baseApi + '/bank/user/getCardInfo',
    method: 'get',
    params
  })
}
// 产品列表
export function productList(data) {
  return request({
    url: baseApi + '/bank/product/list',
    method: 'post',
    data
  })
}
// 修改客户备注
export function customerUpdateRemarks(params) {
  return request({
    url: baseApi + '/bank/applyUser/updateRemarks',
    method: 'post',
    params
  })
}
// 修改业务员备注
export function userUpdateRemarks(params) {
  return request({
    url: baseApi + '/bank/user/updateRemarks',
    method: 'post',
    params
  })
}
// 产品详情
export function productDetail(params) {
  return request({
    url: baseApi + '/bank/product/details',
    method: 'post',
    params
  })
}
// 管理客户列表
export function getUserList(data) {
  return request({
    url: baseApi + '/bank/applyUser/getUserList',
    method: 'post',
    data
  })
}
// 客户详情
export function getUserDetail({ params, userId }) {
  return request({
    url: baseApi + '/bank/applyUser/getUserInformation/' + userId,
    method: 'get',
    params
  })
}
// 推荐经纪人列表
export function getAgentList(data) {
  return request({
    url: baseApi + '/bank/user/getUserList',
    method: 'post',
    data
  })
}
// 推荐经纪人列表
export function getSysRegionByLevel(params) {
  return request({
    url: baseApi + '/bank/backstage/product/getSysRegionByLevel',
    method: 'get',
    params
  })
}
// 经纪人详情
export function getAgentDetail({ params, userId }) {
  return request({
    url: baseApi + '/bank/user/getUserDetail/' + userId,
    method: 'get',
    params
  })
}
// 银行卡列表
export function getBankList(data) {
  return request({
    url: baseApi + '/bank/userBank/list',
    method: 'post',
    data
  })
}
// 添加银行卡
export function addBank(data) {
  return request({
    url: baseApi + '/bank/userBank/addBank',
    method: 'post',
    data
  })
}
// 添加银行卡
export function delBank(params) {
  return request({
    url: baseApi + '/bank/userBank/removeBank',
    method: 'post',
    params
  })
}
// 获取当前账户的信息
export function getMyDetail(params) {
  return request({
    url: baseApi + '/bank/user/getMyDetail',
    method: 'get',
    params
  })
}
// 设置是否展示姓名
export function setShowName(params) {
  return request({
    url: baseApi + '/bank/user/setShowName',
    method: 'post',
    params
  })
}
// 设置密码
export function setPassword(params) {
  return request({
    url: baseApi + '/bank/user/setPassword',
    method: 'post',
    params
  })
}
// 修改密码
export function changePassword(data) {
  return request({
    url: baseApi + '/bank/user/changePassword',
    method: 'post',
    data
  })
}
// 收入列表
export function getIncomeList(data) {
  return request({
    url: baseApi + '/bank/revenue/list',
    method: 'post',
    data
  })
}
// 我的订单
export function myOrderList(data) {
  return request({
    url: baseApi + '/bank/order/myOrderList',
    method: 'post',
    data
  })
}
// 结算单列表
export function getSettleList(data) {
  return request({
    url: baseApi + '/bank/settlement/list',
    method: 'post',
    data
  })
}
// 结算单详情
export function getSettleDetail(params) {
  return request({
    url: baseApi + '/bank/settlement/getSettlementDetail',
    method: 'get',
    params
  })
}
// 订单详情
export function orderDetail(params) {
  return request({
    url: baseApi + '/bank/order/orderDetail',
    method: 'get',
    params
  })
}
// 临时订单详情
export function orderTemp(params) {
  return request({
    url: baseApi + '/bank/order/orderTemp',
    method: 'get',
    params
  })
}
// 订单详情-回访
export function visitOrderDetail(params) {
  return request({
    url: baseApi + '/bank/order/visitOrderDetail',
    method: 'get',
    params
  })
}
// 根据标签类型获取数据
export function getTagList(params) {
  return request({
    url: baseApi + '/bank/config/list',
    method: 'get',
    params
  })
}
// 用户数据编辑
export function saveUserInfo(data) {
  return request({
    url: baseApi + '/bank/user/edit',
    method: 'post',
    data
  })
}
// 用户推荐产品编辑
export function saveUserProduct(params) {
  return request({
    url: baseApi + '/bank/product/add',
    method: 'post',
    params
  })
}
// 推荐产品
export function getUserProduct(params) {
  return request({
    url: baseApi + '/bank/product/infoList',
    method: 'get',
    params
  })
}
// 实名认证
export function authentication(data) {
  return request({
    url: baseApi + '/bank/user/authentication',
    method: 'post',
    data
  })
}
// 保存回访
export function saveReturnVisit(data) {
  return request({
    url: baseApi + '/orderReturnVisit/save',
    method: 'post',
    data
  })
}
// 上传文件
export function filesAnon(param) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return request.post(baseApi + '/file/files-anon', param, config)
}

// 获取oss数据
export function getOssInfo() {
  return request({
    url: baseApi + '/files/getToken',
    method: 'post'
  })
}

// 获取银行卡名称
export function getBankName(bankNo) {
  return request({
    url: baseApi + '/bank/userBank/getBankName?bankNo=' + bankNo,
    method: 'post'
  })
}

// 获取微信签名
export function getJsapiTicket() {
  return request({
    url: '/survey-center/survey/getJsapiTicket',
    method: 'post'
  })
}

// 获取启用的上线城市城市
export function getCityBySysId(params) {
  return request({
    url: `${baseApi}/bank/admins/onlineCity/getCityBySysId`,
    method: 'get',
    params
  })
}
