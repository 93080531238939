import axios from 'axios'
import { Toast } from 'vant'
import { getToken } from '@/utils/auth'
import router from '@/router'
import store from '@/store'

const whiteApi = ['/api-auth/authentication/mobile', '/api-auth/oauth/user/h5/token', '/api-auth/oauth/user/token']

// create an axios instance
const host = '/api/v1'
// const host = 'https://maodidikeji.com/api/v1'
// const host = 'http://192.168.0.188:9200/'
// const host = 'http://39.108.10.219/'
const service = axios.create({
  baseURL: host,
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.url === '/api-auth/code/sms' || config.url === '/api-auth/authentication/mobile') {
      return config
    }
    // do something before request is sent
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    console.log(response, '2222')
    const res = response.data
    // 是否白名单中的请求，是则跳过拦截
    if (whiteApi.indexOf(response.config.url) !== -1) {
      return res
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.resp_code !== 0 && res.resp_code !== '200' && res.code !== 0) {
      if (res.resp_code === '99887' || res.resp_code === '99888' || res.resp_code === 99997) {
        store.dispatch('user/loginOut')
        Toast({
          message: '登录失效，请重新登录!',
          forbidClick: true,
          duration: 1000,
          onClose: () => {
            router.push({ name: 'login' })
          }
        })
        return Promise.reject(new Error(res.resp_msg || 'Error'))
      }
      if (response.config.passCheck) return res // 如果设置了passcheck属性则不做检查
      Toast(res.resp_msg)
      return Promise.reject(new Error(res.resp_msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Toast('网络异常，请稍后重试！')
    return Promise.reject(error)
  }
)

export default service
