import request from '@/utils/request'
import qs from 'qs'
const baseApi = '/api-user'
// 当前帐号用户详情
export function getMyDetail(params) {
  return request({
    url: `${baseApi}/personal/getClientCurrentPersonalDetail`,
    method: 'get',
    params
  })
}
// 获取h5页面
export function getShareUrls(data) {
  return request({
    url: `${baseApi}/userShare/getShareUrls`,
    method: 'post',
    data
  })
}
// 新增所有信息(征信，基本信息，资产)
export function addUserInfoAll(data) {
  return request({
    url: `${baseApi}/user_info/addUserInfoAll`,
    method: 'post',
    data
  })
}
// 用户匹配经理人
export function sayManagers(data) {
  return request({
    url: `${baseApi}/users/sayManagers`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 同城经理人
export function localCityManager(data) {
  return request({
    url: `${baseApi}/users/localCityManager`,
    method: 'post',
    passCheck: true,
    data
  })
}
// 附近经理人
export function nearbyManager(data) {
  return request({
    url: `${baseApi}/users/nearbyManager`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 用户当前订单状态及id
export function orderState(data) {
  return request({
    url: `${baseApi}/users/orderState`,
    method: 'post',
    data
  })
}
// 获取业务员信息
export function getUserInfo(params) {
  return request({
    url: `${baseApi}/users/userInfo`,
    method: 'get',
    params
  })
}
// 设置与业务员已沟通状态
export function bindUserFriend(data) {
  return request({
    url: `${baseApi}/users/bindUserFriend`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查看聊天的更多内容按钮状态
export function hasThreePoints(params) {
  return request({
    url: `${baseApi}/user_info/hasThreePoints`,
    method: 'get',
    params
  })
}
// 获取还可更换业务员次数
export function countNum(params) {
  return request({
    url: `${baseApi}/usersNearby/countNum`,
    method: 'get',
    params
  })
}
// 更换经理人
export function changeUserFriend(data) {
  return request({
    url: `${baseApi}/usersNearby/changeUserFriend`,
    method: 'post',
    data
  })
}
// 用户当前订单id查贷款信息
export function selectOrderById(data) {
  return request({
    url: `${baseApi}/users/selectOrderById`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 是否存在聊一聊关系
export function getFriendBindState(data) {
  return request({
    url: `${baseApi}/users/getFriendBindState`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置密码
export function setPassword(data) {
  return request({
    url: `${baseApi}/personal/setPassword`,
    method: 'post',
    data
  })
}
// 修改密码
export function resetPassword(data) {
  return request({
    url: `${baseApi}/personal/resetPassword`,
    method: 'post',
    data
  })
}
// 用户基本信息 根据类型来获取已选中的树状字典
export function selectUserDictByType(params) {
  return request({
    url: `${baseApi}/user_info/selectUserDictByType`,
    method: 'get',
    params
  })
}
// 帮助建议
export function saveSuggest(params) {
  return request({
    url: `${baseApi}/personal/saveSuggest`,
    method: 'get',
    params
  })
}
// 根据code获取协议
export function selectByCode(params) {
  return request({
    url: `${baseApi}/agreement/selectByCode`,
    method: 'get',
    params
  })
}
// 判断城市是否为开放城市
export function checkCity(params) {
  return request({
    url: `${baseApi}/users/checkCity`,
    method: 'get',
    params
  })
}
// 身份认证
export function userAuthentication(data) {
  return request({
    url: `${baseApi}/personal/authentication`,
    method: 'post',
    data
  })
}
// 根据code获取对应协议 ym006 银行卡协议
export function getAgreementByCode(params) {
  return request({
    url: `${baseApi}/agreement/selectByCode`,
    method: 'get',
    params
  })
}
// 用户贷款成功后对经理人的评价
export function addComment(data) {
  return request({
    url: `${baseApi}/userComment/addComment`,
    method: 'post',
    data
  })
}
// 经理人详情界面获取该业务员被评论列表
export function getCommentList(data) {
  return request({
    url: `${baseApi}/userComment/getCommentList`,
    method: 'post',
    data
  })
}
// 获取我的投诉列表
export function getComplaintList(data) {
  return request({
    url: `${baseApi}/complaint/getComplaintList`,
    method: 'post',
    data
  })
}
// 发表投诉
export function addComplaint(data) {
  return request({
    url: `${baseApi}/complaint/addComplaint`,
    method: 'post',
    data
  })
}
// 获取卡券列表
export function getCouponList(data) {
  return request({
    url: `${baseApi}/user/coupons/list`,
    method: 'post',
    data
  })
}
// 卡卷对应数量
export function groupCount(params) {
  return request({
    url: `${baseApi}/user/coupons/groupCount`,
    method: 'get',
    params
  })
}
// 获取微信JsapiTicket
export function getJSUrl(data) {
  return request({
    url: '/survey-center/survey/getJsapiTicket',
    method: 'post',
    data
  })
}

// 获取我的收益
export function getUserIncome(params) {
  return request({
    url: `${baseApi}/users/userShareIncome/getUserIncome`,
    method: 'get',
    params
  })
}
// 获取邀约列表
export function listInviteUsers(data) {
  return request({
    url: `${baseApi}/userShare/listInviteUsers`,
    method: 'post',
    data
  })
}
// 获取账户信息(余额等)
export function getAccountInfo(data) {
  return request({
    url: `${baseApi}/user/withdrawal/getAccountInfo`,
    method: 'post',
    data
  })
}
// 提现
export function submitApply(data) {
  return request({
    url: `${baseApi}/user/withdrawal/submitApply`,
    method: 'post',
    data
  })
}
// 提现详情
export function getWithdrawalDetail(params) {
  return request({
    url: `${baseApi}/user/withdrawal/getWithdrawalDetail`,
    method: 'get',
    params
  })
}
// 提现列表
export function listWithdrawal(data) {
  return request({
    url: `${baseApi}/user/withdrawal/listWithdrawal`,
    method: 'post',
    data
  })
}
// 我的战绩
export function listUserIncomeRecordOfSharePage(data) {
  return request({
    url: `${baseApi}/users/userShareIncome/listUserIncomeRecordOfSharePage`,
    method: 'post',
    data
  })
}
// 邀请公告滚动
export function listWithdrawalDataWithShareHeader(params) {
  return request({
    url: `${baseApi}/user/withdrawal/listWithdrawalDataWithShareHeader`,
    method: 'get',
    params
  })
}
// 获取邀请码
export function getUserShareCode(params) {
  return request({
    url: `${baseApi}/userShare/getUserShareCode`,
    method: 'get',
    params
  })
}
// 修改昵称
export function changeNickname(params) {
  return request({
    url: `${baseApi}/personal/changeNickname`,
    method: 'get',
    params
  })
}
// 获取默认头像列表
export function getDefaultAatar(params) {
  return request({
    url: `${baseApi}/personal/getDefaultAatar`,
    method: 'get',
    params
  })
}
// 设置头像
export function changeHeadImg(params) {
  return request({
    url: `${baseApi}/personal/changeHeadImg`,
    method: 'get',
    params
  })
}
// 点开红包对应列表,如果返回数量为0,则没有红包界面
export function redCouponsList(params) {
  return request({
    url: `${baseApi}/user/coupons/redCouponsList`,
    method: 'get',
    params
  })
}
